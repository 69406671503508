import React, { Fragment, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Link } from 'gatsby';
import { get } from 'lodash'
import { addYears } from 'date-fns'
import Button from '../Button/Button'
import './CookieConsent.scss';

const CookieConsent = ({text, CTAText}) => {
  const [cookies, setCookie] = useCookies(['cookieConsent'])
  const [gdpr, setGdpr] = useState(1)

  useEffect(() => {
    setGdpr(get(cookies, 'gdpr', 0))
  }, [])

  const handleOnClick = () => {
    console.log('set cookie!')
    const nextYear = addYears(new Date(), 1)

    setCookie('gdpr', 1, {
      path: '/',
      expires: nextYear
    })
    setGdpr(1)
  }

  console.log('cookies init')

  return (
    <Fragment>
      {gdpr === 0 &&
      <div className='gdpr'>
        <div className='gdpr_content'>
          <div className='row justify-content-md-center'>
            <div className='col-md-8 text_container'>
              {text}
            </div>
            <div className='col-md-4 button_container'>
            <Button type={`button`} text={CTAText} onClick={handleOnClick} />
            </div>
          </div>
        </div>
      </div>
      }
    </Fragment>
  )
}

export default CookieConsent
