import { Link } from 'gatsby'
import React from 'react'
import s from './button.scss'

const Button = props => {
  if (props.isLink) {
    if (props.isInternal) {
      return (
        <Link to={props.linkTo} className={`button_101 ${props.active ? 'active' : ''}`} styles={props.bStyles} {...props}>
          {props.text}
        </Link>
      )
    } else {
      return (
        <a href={props.linkTo} className={`button_101 ${props.active ? 'active' : ''}`} styles={props.bStyles} {...props}>
          {props.text}
        </a>
      )
    }
  } else {
    return (
      <button disabled={props.disabled} className={`button_101 ${props.active ? 'active' : ''}`} styles={props.bStyles} onClick={props.onClick} {...props}>
        {props.text}
      </button>
    )
  }
}

export default Button
