import { Link } from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'
import Dots from '../../shared/Dots/Dots'
import Close from '../../shared/Close/Close'
import './header.scss'

const Header = ({ navigationLinks }) => {
  const [isNavOpen, toggleNavigation] = useState(false)
  const scrollPosition = useRef(0)

  const hideNav = e => {
    toggleNavigation(false)
    window.removeEventListener('scroll', hideNav)
    document.body.removeEventListener('click', hideNavOnClick)
  }

  const hideNavOnScroll = e => {
    const scrollPos = window.pageYOffset
    const scrollStartPos = scrollPosition.current
    if (scrollPos > scrollStartPos + 120) {
      hideNav()
    }
  }

  const hideNavOnClick = e => {
    if (e.target.classList.contains('navigation_container')) {
      hideNav()
    }
  }

  useEffect(() => {
    if (isNavOpen) {
      scrollPosition.current = window.pageYOffset
      window.addEventListener('scroll', hideNavOnScroll)
      document.body.addEventListener('click', hideNavOnClick)
    }

    return () => window.removeEventListener('scroll', hideNavOnScroll)
  }, [isNavOpen, toggleNavigation])

  return (
  <>
    <header className={`container-fluid${isNavOpen ? ' nav_open' : ''}`}>
      <div
        className="header_logo_container"
      >
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img
            src={'/logo-sambandid.svg'}
            className="header_101_logo"
            alt="101 Sambandið logo" />
        </Link>
      </div>
    </header>
      <div style={{ display: isNavOpen ? '' : 'none' }} className="navigation_container">
        <div className="navigation">
          <ul>
            {
              navigationLinks.map(navItem => {
                if (navItem.isExternal) {
                  return (
                    <li key={navItem.linkText}>
                      <a href={`${navItem.externalLink}`} target="_blank">{navItem.linkText}</a>
                    </li>
                  )
                } else {
                  return (
                    <li key={navItem.linkText}>
                      <Link to={`/${navItem.internalLink.slug}`}>{navItem.linkText}</Link>
                    </li>
                  )
                }
              })
            }
          </ul>
        </div>
      </div>
    </>
  )
}

export default Header
