import React, { useState } from 'react'
import './Notification.scss'
import Text from '../Text/Text'
import MonospacedText from '../MonospacedText/MonospacedText'

export default ({ headline, text }) => {
  const [destroy, setDestroy] = useState(false)

  const destroyMe = () => {
    setTimeout(() => {
      setDestroy(true)
    }, 350)
  }

  if (destroy) {
    return null
  }

  return (
    <div className={`notification_101_popup`}>
      <div>
        <Text style={{ color: '#000', fontWeight: '500', marginBottom: '4px' }} text={headline} />
        <Text style={{ color: 'rgba(0,0,0,0.6)', fontWeight: '400', fontSize: '.74em', marginBottom: '10px' }} text={text} />
      </div>
      <div role={'button'} onClick={() => destroyMe()} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <MonospacedText text={`LOKA`} eStyles={{ borderBottom: '2px solid #f00', fontWeight: '500', lineHeight: 1, cursor: 'pointer', color: '#000', paddingTop: '10px', textAlign: 'center', fontSize: '.6em', textTransform: 'uppercase', marginBottom: '5px' }}/>
      </div>
    </div>
  )
}
