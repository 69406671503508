import React from 'react'
import './Footer.scss'
import Layout from '../../shared/Layout/layout'
import Text from '../../shared/Text/Text'
import { Link } from 'gatsby'

export default ({ hideNav }) => {
  if (hideNav) {
    return <></>
  }

  return (
    <footer>
      <Layout>
        <div>
          <img className="logo_101 img-fluid" src={`/101logo.svg`} alt="" />
          <div className="footer_social_container">
            <a href="https://facebook.com/101sambandid">
              <img className="footer_social_facebook" src={`/fb.svg`} alt="" />
            </a>
            <a href="https://twitter.com/101sambandid">
              <img className="footer_social_twitter" src={`/tw.svg`} alt="" />
            </a>
            <a href="https://www.instagram.com/101liveradio/">
              <img
                className="footer_social_instagram"
                src={`/insta.svg`}
                alt=""
              />
            </a>
          </div>
        </div>
        <div>
          <h5>Opnunartími Þjónustuvers</h5>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li>
              <Text text="Mánudagar – föstudagar: 9:00 – 17:00" />
            </li>
            <li>
              <Text text="Laugardagar: Lokað" />
            </li>
            <li>
              <Text text="Sunnudagar: Lokað" />
            </li>
            <li>
              <a href="http://m.me/101sambandid">
                <Text text="Opna spjall við þjónustuver" />
              </a>
            </li>
            <li>
              <a href="mailto:sambandid@sambandid.is">
                <Text text="sambandid@sambandid.is" />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h5>101 Sambandið</h5>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li>
              <a target="_blank" href="https://goo.gl/maps/KegiAL7WFahG6gtg9">
                <Text text="Hverfisgata 78, 101 Reykjavík " />
              </a>
            </li>
            <li>
              <Link to="/skilmalar">
                <Text text="Skilmálar" />
              </Link>
            </li>
            <li>
              <Link to="/personuverndarstefna">
                <Text text="Persónuverndarstefna" />
              </Link>
            </li>
            <li>
              <Link to="/skilmalar-um-notkun-a-vefkokum">
                <Text text="Vefkökustefna" />
              </Link>
            </li>
            <li>
              <a target="_blank" href="https://101.live">
                <Text text="101.live" />
              </a>
            </li>
          </ul>
        </div>
      </Layout>
    </footer>
  )
}
