import React from 'react'
import AppIcons from '../shared/AppIcons/AppIcons'
import Links from '../shared/Links/Links'
import LazyloadImage from '../utils/LazyloadContentfulImage'

export const options = {
  renderNode: {
    'embedded-asset-block': node => <LazyloadImage className="img-fluid" style={{ margin: '20px 0px' }} alt="" imageUrl={`${node.data.target.fields.file['en-US'].url}`} />,
    'embedded-entry-inline': node => {
      // needs fix - problem with gatsby cache after rich text edited in CF
      return <AppIcons />
      // switch (node.data.target.fields.nameOfField['en-US']) {
      //   case 'App Icons':
      //     return <AppIcons />
      //   default:
      //     return node
      // }
    },
    'entry-hyperlink': node => {
      switch (node.data.target.sys.type) {
        case 'Entry':
          return (
            <Links
              linkTo={node.data.target.fields.slug['en-US']}
              linkText={node.content[0].value}
            />
          )
        default:
          return <></>
      }
    },
    hyperlink: node => {
      return (
        <Links
          linkTo={node.data.uri}
          linkText={node.content[0].value}
          isExternal
        />
      )
    },
  },
}
