import React, { useState } from 'react'
import Button from '../../shared/Button/Button'
import s from './BottomNav.scss'

export default props => {
  const [showButtons, setShowButtons] = useState(false)

  const toggleButtons = () => {
    setShowButtons(!showButtons)
  }

  return (
    <></>
  )
}
