import React from 'react'
import { FaAndroid, FaApple, FaSimCard } from 'react-icons/fa';
import { Link } from 'gatsby';
import Text from '../Text/Text'

export default () => (
  <div style={{paddingTop: '10px'}}>
    <Link to="/panta-simkort" style={{cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '15px 0px'}}>
      <FaSimCard style={{marginRight: '10px'}} color="#fff" size={'1.45em'} />
      <Text style={{ margin: 0, color: '#fff', fontWeight: 'bold' }} text="Panta símkort" />
    </Link>
    <a href={'https://apps.apple.com/is/app/101-sambandið/id1473259942'} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '5px 0px 20px'}}>
      <FaApple style={{marginRight: '10px'}} color="#fff" size={'1.5em'} />
      <Text style={{ margin: 0, color: '#fff', fontWeight: 'bold' }} text="Sækja Sambandið fyrir Apple" />
    </a>
    <a href={'http://play.google.com/store/apps/details?id=is.sambandid.application'} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '15px 0px'}}>
      <FaAndroid style={{marginRight: '10px'}} color="#fff" size={'1.5em'} />
      <Text style={{ margin: 0, color: '#fff', fontWeight: 'bold' }} text="Sækja Sambandið fyrir Android" />
    </a>
  </div>
)
