import React, { useState, useEffect } from 'react'

export default ({ className, placeholderUrl, shouldLoad, imageUrl, propStyles }) => {
  const [loaded, useLoaded] = useState(false)
  const onLoad = () => useLoaded(true)
  const image = <img className={className} alt="" style={{ opacity: loaded ? 1 : 0, ...imgStyles }} src={imageUrl} onLoad={onLoad} />

  useEffect(() => {
    const imgTimeout = setTimeout(() => {
      useLoaded(true)
    }, 1500)

    return () => clearTimeout(imgTimeout)
  }, [])

  return (
    <div style={{ ...containerStyles, ...propStyles }}>
      <img className={className} alt="" style={{ ...placeholderStyles, ...imgStyles }} src={`${imageUrl}?w=10&h=10`} />
      { shouldLoad ? image : typeof shouldLoad !== 'undefined' ? null : image }
      <div style={{ paddingBottom: '50.0%' }} />
    </div>
  )
}

const containerStyles = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '25px'
}

const placeholderStyles = {
  filter: 'blur(50px)',
  transform: 'scale(1)'
}

const imgStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transition: `opacity .45s linear`
}
