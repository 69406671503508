import React from 'react'
import './PageWrapper.scss'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import BottomNav from '../../components/BottomNav/BottomNav'
import Notification from '../Notification/Notification.js'
import CookieConsent from '../CookieConsent/CookieConsent.js'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { StaticQuery, graphql } from 'gatsby'
import { options } from '../../blocks/options';

export default ({ children, state, hideNav }) => (
  <StaticQuery
    query={graphql`
    query {
      contentfulMasterLayout {
        navigationLinks {
          linkText
          isExternal
          externalLink
          internalLink {
            slug
          }
        }
        cookieConsentCtaText
        cookieConsentText {
          json
        }
      }
    }    
    `}
    render={data => {
      return (
        <>
        <Header navigationLinks={data.contentfulMasterLayout.navigationLinks}/>
        <main>
          {children}
        </main>
        <Footer hideNav={hideNav} />
        <BottomNav hideNav={hideNav} />
        <CookieConsent text={documentToReactComponents(data.contentfulMasterLayout.cookieConsentText.json, options)} CTAText={data.contentfulMasterLayout.cookieConsentCtaText}/>
        { state && state.showNotification && <Notification headline={state.showNotification.headline} text={state.showNotification.text} /> }
        </>
      )
    }}
  />
)
