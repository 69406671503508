import React from 'react'
import './Links.scss'
import { Link } from 'gatsby'

export default ({ isExternal, linkTo, linkText }) => {
  if (isExternal) {
    return <a className="link_101" href={linkTo}>{linkText}</a>
  } else {
    return <Link className="link_101" to={`/${linkTo}`}>{linkText}</Link>
  }
}
