import React from 'react'
import '../../styles/index.scss'

const Layout = ({ children, styles }) => (
    <div className="container" style={styles}>
      {children}
    </div>
)

export default Layout
